import * as React from "react";
import { graphql, Link } from "gatsby";
import { BlogLayout } from "../components/blog/BlogLayout";
import { BlogPostPreview } from "../components/blog/template/common/BlogPostPreview";
import { BlogPostData } from "../data/types/BlogPostData";
import { Pagination, PaginationItem, Box } from "@mui/material";

export const query = graphql`
  query BlogListTemplateQuery($skip: Int!, $limit: Int!) {
    blogPosts: allSanityBlogPosts(
      sort: { fields: postDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        postDate(formatString: "DD MMMM YYYY")
        _rawSummaryText
        _rawBodyText
        tags
      }
    }
  }
`;

interface PostData {
  nodes: BlogPostData[];
}

interface TemplateData {
  blogPosts: PostData;
}

interface ContextData {
  currentPage: number;
  numPages: number;
}

export interface IBlogListTemplateProps {
  data: TemplateData;
  pageContext: ContextData;
}

export default function BlogPostListPage(props: IBlogListTemplateProps) {
  const blogPosts = props.data.blogPosts.nodes;
  const title =
    props.pageContext.currentPage == 1
      ? "Blog Posts"
      : `Blog Posts page ${props.pageContext.currentPage}`;
  return (
    <BlogLayout title={title} noPaper sectionTitle="Viewing all blog posts">
      {blogPosts.map((x) => {
        return <BlogPostPreview post={x} key={x.id} />;
      })}
      <Box justifyContent="center" display="flex">
        <Pagination
          size="large"
          count={props.pageContext.numPages}
          page={props.pageContext.currentPage}
          color="primary"
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={item.page === 1 ? "/blog" : `/blog/${item.page}`}
              {...item}
            />
          )}
        />
      </Box>
    </BlogLayout>
  );
}
